body {
  margin: 0;
  background: #f5f5f5;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (min-width: 300px) {

  .input_table{
    padding-left: 25% !important;
  }

  .billing_address_check{
    float: left;
  }

   .personal_info_col{
    padding: '10px 20px 20px 0px' !important;
  }

  .billing_info_col{
    padding: '0px 20px 20px 0px' !important; 
  }

  .phone_captcha_div{
    margin: 10px 50px !important;
  }

  .phone_captcha_container{
    display: none;
  }

  .captcha_div{
    display: none;
  }


}

@media only screen and (min-width: 600px) {

  .input_table{
    padding-left: 30% !important;
  }

  .personal_info_col{
    padding: '10px 20px 0px 0px' !important;
  }

  .billing_info_col{
    padding: '0px 20px 20px 0px' !important; 
  }   

  .phone_captcha_div{
    margin: 10px 55px !important;
  }

  .phone_captcha_container{
    display: block;
  }

  .captcha_div{
    display: none;
  }

}

@media only screen and (min-width: 768px) {

  .extra_height_div{
    height: 733px !important;
  }

  .input_table{
    padding-left: 36% !important;
  }

  .personal_info_col{
    padding: '10px 20px 20px 0px' !important;
  }

  .billing_info_col{
    padding: '0px 20px 20px 0px' !important; 
  }

  .phone_captcha_container{
    display: block;
  }

  .phone_captcha_div{
    margin: 10px 130px !important;
  }

  .captch_container{
    height: 195px;
  }

  .captcha_div{
    display: none;
  }

}

@media only screen and (min-width: 992px) {

  .extra_height_div{
    height: 710px !important;
  }

  .input_table{
    padding-left: 36% !important;
  }

  .personal_info_col{
    padding: '10px 20px 20px 0px' !important;
  }

  .billing_info_col{
    padding: '0px 20px 20px 0px' !important; 
  }

  .phone_captcha_container{
    display: none;
  }

  .captch_container{
    height: 171px;
  }

  .captcha_div{
    display: block;
    padding: 42px 55px !important; 
  }

}

@media only screen and (min-width: 1200px) {
  
  .extra_height_div{
    height: 710px !important;
  }

  .input_table{
    padding-left: 0% !important;
  }

  .personal_info_col{
    padding: '10px 20px 20px 0px' !important;
  }

  .billing_info_col{
    padding: '0px 20px 20px 0px' !important; 
  }

  .phone_captcha_container{
    display: none;
  }

  .captch_container{
    height: '171px';
  }

  .captcha_div{
    display: block;
    padding: 42px 92px !important; 
  }

}

.cursor_pointer {
  cursor: pointer !important;
}

.error_message {
  color: red;
  font-size: 0.75em;
  margin-top: 2px;
}

.user_created_message_login {
  color: forestgreen;
  font-size: 1em;
}

.user_created_message_errors{
  color: red;
  font-size: 1em;
}

.reset_password_success_msg {
  color: forestgreen !important;
  font-size: 1em;
}

.reset_password_error_msg{
  color: red !important;
  font-size: 1em;
}

.profile_edit_success_msg {
  color: forestgreen;
  font-size: 0.85em;
}

.clear_cart_icon_order_section{
  color: red !important;
  font-size: 1em;
  cursor: pointer;
}

.form_error_message {
  color: red;
  font-size: 0.85em;
  text-transform: capitalize;
}

.form_success_message{
  color: forestgreen;
  font-size: 0.85em;
  text-transform: capitalize;
}

.validate_email_message {
  color: forestgreen;
  font-size: 0.8em;
}

.sign_in_error_message {
  color: red;
  font-size: 0.8em;
}

.user_created_message_login {
  color: forestgreen;
  margin-top: 10px;
}

.user_created_message_errors{
  color: red;
  margin-top: 10px;
}

.forgot_password{
  color: #0879FA;
  text-decoration: none;
  font-size: 0.9rem;
}

.resend_validation_link{
  color: #0879FA;
  text-decoration: none;
  font-size: 0.9rem;
}

.signup_user_section_icon{
  margin: 0px 5px 5px 5px;
  color: #0879FA;
  text-decoration: none;
  font-size: 0.9rem;;
}

.signup_billing_section_icon{
  margin: 0px 5px 5px 5px;
  color: #0879FA;
  text-decoration: none;
  font-size: 0.9rem;
}

.signup_contact_section_icon{
  margin: 0px 5px 3px 5px;
  color: #0879FA;
  text-decoration: none;
  font-size: 0.9rem;;
}

.signup_billing_address_section_icon{
  margin: 0px 5px 5px 5px;
  color: #0879FA;
  text-decoration: none;
  font-size: 0.9rem;;
}

.signup_login_section_icon{
  margin: 0px 5px 5px 5px;
  color: #0879FA;
  text-decoration: none;
  font-size: 0.9rem;;
}

.home_section_icon{
  margin: 0px 5px 5px 5px;
  color: #0879FA;
  text-decoration: none;
  font-size: 0.9rem;
}

.home_shopping_section_cart_icon{
  margin: 0px 5px 5px 5px;
  text-decoration: none;
  font-size: 0.9rem;
}

.home_shopping_section_quote_icon{
  margin: 4px 5px 5px 5px;
  text-decoration: none;
  font-size: 0.9rem;
}

.home_license_section_icon{
  margin: 0px 5px 5px 5px;
  text-decoration: none;
  font-size: 0.9rem;
}

.renewal_home_icon{
  margin: 0px 5px 5px 5px;
  color: #0879FA;
  text-decoration: none;
  font-size: 0.9rem;
  cursor: pointer;
}

.home_account_section_icon{
  margin: 0px 5px 5px 5px;
  text-decoration: none;
  font-size: 0.9rem;
}

.home_invoice_section_icon{
  margin: 0px 5px 5px 5px;
  text-decoration: none;
  font-size: 0.9rem;
}

.breadcrumb_container > nav {
  padding: 0px !important;
  margin: 0px!important;
}

.breadcrumb_dashboard > ol {
  margin: 0px !important;
}

.modify_profile_icon{
  margin: 0px 5px 5px 5px;
  color: #0879FA;
  text-decoration: none;
  font-size: 0.9rem;
}
.subscription_icon{
  margin: 0px 5px 5px 5px;
  color: #0879FA;
  text-decoration: none;
  font-size: 0.9rem;
}
.change_password_icon{
  margin: 0px 5px 5px 5px;
  color: #0879FA;
  text-decoration: none;
  font-size: 0.9rem;
}

.logout_icon{
  margin: 4px 5px 5px 5px;
  color: #0879FA;
  text-decoration: none;
  font-size: 0.9rem;
}

.modify_profile{
  color: #0879FA;
  text-decoration: none;
  font-size: 0.95rem;
}

.change_password{
  color: #0879FA;
  text-decoration: none;
  font-size: 0.95rem;
}
.manage_subscription{
  color: #0879FA;
  text-decoration: none;
  font-size: 0.95rem;
}
.logout{
  color: #0879FA;
  text-decoration: none;
  font-size: 0.95rem;
}

.home_license_section_inner_icon{
  margin: 0px 5px 5px 5px;
  text-decoration: none;
  font-size: 0.9rem;
}

.license_summary_text{
  color: #0879FA;
  font-size: 0.95rem;
}

.invoice_summary_text{
  color: #0879FA;
  font-size: 0.95rem;
  font-style: italic;
}

.order_summary_text{
  color: #0879FA;
  font-size: 0.95rem;
}

.order_subs_text{
  color: #0879FA;
  font-size: 0.95rem;
  margin: 0px 0px 0px 10px;
}

.order_cart_section_icon{
  margin: 0px 5px 5px 5px;
  color: #0879FA;
  text-decoration: none;
  font-size: 0.9rem;
}

.order_minus_icon{
  margin: 6px 5px 5px 5px;
  color: #0879FA;
  text-decoration: none;
  font-size: 0.9rem;
  cursor: pointer;
}

.order_plus_icon{
  margin: 6px 5px 5px 5px;
  color: #0879FA;
  text-decoration: none;
  font-size: 0.9rem;
  cursor: pointer;
}

.order_pricing_section_icon{
  margin: 4px 10px 5px 5px;
  color: #58A847;
  text-decoration: none;
  font-size: 0.9rem;
}

.order_table_form_group_col .form-group input {
  padding: 5px;
  width: 50px;
  height: 25px;
}

.order_table_form_group_col{
  text-align: center;
}

.order_table_form_group_col .form-group {
  margin: 0px;
  padding: 0px 0px 0px 10px;
}

.order_license_section_inner_icon{
  margin: 0px 5px 5px 5px;
  text-decoration: none;
  font-size: 0.9rem;
}

.customer_data_section_icon{
  margin: 2px 5px 5px 5px;
  color: #0879FA;
  text-decoration: none;
  font-size: 0.9rem;
}

.customer_data_text{
  color: #0879FA;
  font-size: 0.95rem;
  font-style: italic;
}

.change_password_section_icon{
  margin: 2px 5px 5px 5px;
  color: #0879FA;
  text-decoration: none;
  font-size: 0.9rem;
}

.change_password_info_section_icon{
  margin: 2px 5px 5px 5px;
  color: #58A847;
  text-decoration: none;
  font-size: 0.9rem;
}

.payment_cart_section_icon{
  margin: 3px 5px 5px 5px;
  color: #0879FA;
  text-decoration: none;
  font-size: 0.9rem;
}

.payment_billing_section_icon{
  margin: 0px 5px 5px 5px;
  color: #0879FA;
  text-decoration: none;
  font-size: 0.9rem;
}

.payment_subscription_section_icon{
  margin: 3px 5px 5px 5px;
  color: #58A847;
  text-decoration: none;
  font-size: 0.9rem;
}

.payment_modify_billing_section_icon{
  margin: 0px 5px 5px 5px;
  text-decoration: none;
  font-size: 0.9rem;
}

.payment_card_section_icon{
  margin: 3px 5px 5px 5px;
  color: #0879FA;
  text-decoration: none;
  font-size: 0.9rem;
}

.payment_subscription_info_text{
  font-size: 0.9rem;
}

.payment_accordion_header{
  padding: 10px 8px;
  border-bottom: none;
  background-color: #F7F7F7;
}

.quote_section_icon{
  margin: 2px 5px 5px 5px;
  color: #0879FA;
  text-decoration: none;
  font-size: 0.9rem;
}

.admin_section_icon{
  margin: 3px 5px 5px 5px;
  color: #0879FA;
  text-decoration: none;
  font-size: 0.9rem;
}

.show_licenses_icon{
  margin: 0px 5px 5px 5px;
  color: #58A847;
  text-decoration: none;
  font-size: 0.9rem;
  cursor: pointer;
}

.admin_licenses_section_icon{
  margin: 0px 5px 5px 5px;
  color: #0879FA;
  text-decoration: none;
  font-size: 0.9rem;
}

.admin_licenses_section_text{
  color: #0879FA;
  font-size: 0.95rem;
}

#column-type_of_organization{
  text-align: center !important;
}

#column-license_created_on{
  text-align: center !important;
}

#column-type_of_discount{
  text-align: center !important;
}

#column-license_number{
  text-align: center !important;
}

#column-first_name{
  text-align: center !important;
}

#column-last_name{
  text-align: center !important;
}

#column-license_type{
  text-align: center !important;
}

#column-expires_on{
  text-align: center !important;
}

.admin_logout_icon{
  cursor: pointer;
  margin: 5px 5px 5px 5px;
  color: #0879FA;
  text-decoration: none;
  font-size: 0.9rem;
}

/* STRIPE PAYMENTS CSS  */

.form-btn {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}
.StripeElement {
  width: 500px;
  background-color: white;
  height: 40px;
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.rdt_TableHeader{
  display: none !important;
}

/* .g-recaptcha {
  transform:scale(0.77);
  -webkit-transform:scal(0.77);
  transform-origin:0 0;
  -webkit-transform-origin:0 0;
}  */

.select_gender__option{
  text-align: left !important;
}

.select_company__option{
  text-align: left !important;
}

.select_country__option{
  text-align: left !important;
}

.select_billing_country__option{
  text-align: left !important;
}

ul {
  text-align: left;
  list-style-position: inside;
}

.row.gap-y {
  margin-top: 25px;
  margin-bottom: 25px;
}

.w-100 {
  width: 100% !important;
}


.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.media {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: start;
  align-items: flex-start;
}

.lead-6 {
  /* font-size: 2.34375rem !important; */
  font-size: 1.1rem !important;
  color: "#7A7A7A"
}


.lead {
  font-size: 1.1rem;
  color: "#7A7A7A"
}

.w-100 {
  width: 100% !important;
}

.line-height-1 {
  line-height: 1;
}

.w-70px {
  width: 90px !important;
}

.text-dark {
  color: #191919 !important;
}

.w-290px {
  width: 290px !important;
}

.text-dark {
  color: #191919 !important;
}


.media-body {
  -webkit-box-flex: 1;
  flex: 1;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 0.9rem;
  font-weight: 300;
  line-height: 1.9;
  color: "#7A7A7A";
  text-align: left;
  background-color: rgb(255,255,255);
}


.icon-Battery {
  background: url('static/images/icon/Battery-Icon.jpg');
  height: 60px;
  width: 60px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display:inline-block;
}


.icon-Inverter {
  background: url('static/images/icon/Inverter-Icon.jpg');
  height: 60px;
  width: 60px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display:inline-block;
}

.icon-UseCase {
  background: url('static/images/icon/UseCase-Icon.jpg');
  height: 60px;
  width: 60px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display:inline-block;
}


.icon-Data {
  background: url('static/images/icon/Data-Icon.jpg');
  height: 60px;
  width: 60px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display:inline-block;
}


.icon-BOP {
  background: url('static/images/icon/BalanceofPlant-Icon.jpg');
  height: 60px;
  width: 60px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display:inline-block;
}

.icon-Timeseries {
  background: url('static/images/icon/TimeSeries-Icon.jpg');
  height: 60px;
  width: 60px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display:inline-block;
}

.icon-Analytics {
  background: url('static/images/icon/Analytics-Icon.jpg');
  height: 60px;
  width: 60px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display:inline-block;
}


.icon-Report {
  background: url('static/images/icon/Report-Icon.jpg');
  height: 60px;
  width: 60px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display:inline-block;
}

.icon-Output {
  background: url('static/images/icon/Output-Icon.jpg');
  height: 60px;
  width: 60px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display:inline-block;
}

.icon-Logo_A {
  background: url('static/images/icon/Logo_A.jpg');
  height: 50px;
  width: 50px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display:inline-block;
}

/*
.lrgicon-UsrInpt {
  background: url('static/images/lrgicon/UsrInpt.jpg');
  height: 245px;
  width: 250px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display:inline-block;
}
*/
